<template>
 <main class="form-signin">
  <form @submit.prevent="handleSubmit">
   <!--<img class="mb-4" src="/docs/5.1/assets/brand/bootstrap-logo.svg" alt="" width="72" height="57">-->
   <h1 class="h3 mb-3 fw-normal">Please sign in</h1>

   <div class="form-floating">
    <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" v-model="email">
    <label for="floatingInput">Email</label>
   </div>
   <div class="form-floating">
    <input type="username" class="form-control" id="floatingInput" placeholder="Username" v-model="username">
    <label for="floatingInput">Username</label>
   </div>
   <div class="form-floating">
    <input type="password" class="form-control" id="floatingPassword" placeholder="Password" v-model="password">
    <label for="floatingPassword">Password</label>
   </div>

   <div class="checkbox mb-3">
    <label>
     <input type="checkbox" value="remember-me"> Remember me
    </label>
   </div>
   <button class="w-100 btn btn-lg btn-primary" type="submit">Sign in</button>
   <p class="mt-5 mb-3 text-muted">Alredy have an account? <router-link :to="{name: 'Login'}" tag="a">Login.</router-link></p> 
  </form>
 </main>
</template>

<script>
 import {authFetch} from '@/lib/api.js'
 import { register } from '@/lib/auth.js'
 
 export default {
     name: 'Login',
     data(){
         return {
             email: '',
             username: '',
             password: '',
         }
     },
     methods: {
         handleSubmit(){
             register(this.email, this.username, this.password).then(() =>{
                 this.$router.push({ name: 'Login' })
             })
         }
     }
 }
</script>

<style scoped>
 html,
 body {
     height: 100%;
 }

 body {
     display: flex;
     align-items: center;
     padding-top: 40px;
     padding-bottom: 40px;
     background-color: #f5f5f5;
 }

 .form-signin {
     width: 100%;
     max-width: 330px;
     padding: 15px;
     margin: auto;
 }

 .form-signin .checkbox {
     font-weight: 400;
 }

 .form-signin .form-floating:focus-within {
     z-index: 2;
 }

 .form-signin input[type="email"] {
     margin-bottom: -1px;
     border-bottom-right-radius: 0;
     border-bottom-left-radius: 0;
 }

 .form-signin input[type="password"] {
     margin-bottom: 10px;
     border-top-left-radius: 0;
     border-top-right-radius: 0;
 }
</style>
